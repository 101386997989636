import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useCopyToClipboard } from '@lam-agency/toolkit/hooks';
import { TElement } from '../TextAnimation';
import SVG from '../SVG';

interface IProps {
  text: string;
  element?: TElement;
  className?: string;
  copyType?: string;
  overrideDisplay?: string;
}

export const CopyText = ({
  text,
  className,
  element = 'p',
  copyType,
  overrideDisplay
}: IProps) => {
  const { copyStringToClipboard } = useCopyToClipboard();

  const Element = element;

  const copyMessage = copyType
    ? `Copied ${copyType} to clipboard`
    : 'Copied to clipboard';

  const copyToClipboard = () => {
    copyStringToClipboard(text, copyMessage);
  };

  if (!text) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <Element>{overrideDisplay || text}</Element>

      <button onClick={copyToClipboard}>
        <SVG svg="copy" className={styles.icon} />
      </button>
    </div>
  );
};
