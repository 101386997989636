// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--805e2";
export var categoryPages = "styles-module--categoryPages--39bf5";
export var categoryTitle = "styles-module--categoryTitle--7c0fb";
export var container = "styles-module--container--52613";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--f82d6";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var madeWithLAMSVG = "styles-module--madeWithLAMSVG--d805b";
export var mobile = "400px";
export var pageLink = "styles-module--pageLink--c4304";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wordmark = "styles-module--wordmark--8fda4";
export var yearSVG = "styles-module--yearSVG--fe982";