import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { IBentoBlock } from 'lam-toolkit-figma-plugin/dist/figma/sliceParsers/bentoBox';
import { NumberButton } from '../../Common/NumberButton';
import { Button } from '../..';
import { toKebabCase } from '@lam-agency/toolkit/utils';
import { Link } from 'gatsby';

interface ISlide {
  image: string;
  textColor: string;
}

interface IProps extends IBentoBlock {
  className?: string;
}

export const BentoBlock = ({ title, className }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slides = getSlidesFromTitle(title);

  const textColor = slides[activeIndex]?.textColor || 'var(--color-text)';

  return (
    <article className={cn(styles.container, className)}>
      <div className={styles.carouselContainer}>
        <div
          className={styles.carouselRow}
          style={{
            width: `${slides.length * 100}%`,
            transform: `translateX(-${activeIndex * (100 / slides.length)}%)`
          }}
        >
          {slides.map((slide, i) => (
            <div key={i} className={styles.carouselImageContainer}>
              <img className={styles.carouselImage} src={slide.image} />
            </div>
          ))}
        </div>
      </div>

      <Link to={getBlockLinkFromTitle(title)} className={styles.content}>
        <h2 style={{ color: textColor }} className={cn('b2', styles.title)}>
          {title}
        </h2>

        <div className={styles.linkButton}>
          <Button display backgroundColor="white">
            Learn More
          </Button>
        </div>
      </Link>

      {slides.length > 1 && (
        <div className={styles.carouselButtons}>
          {Array.from(Array(slides.length)).map((_, i) => (
            <NumberButton
              number={i + 1}
              onClick={() => setActiveIndex(i)}
              key={i}
              active={activeIndex === i}
            />
          ))}
        </div>
      )}
    </article>
  );
};

// todo - parse this from figma
const getSlidesFromTitle: (title: string) => ISlide[] = (title) => {
  const pathPrefix = '/images/slices/FigmaBentoBox/';

  switch (title) {
    case 'Art Direction':
      return [
        {
          image: `${pathPrefix}ArtDirection1.jpg`,
          textColor: 'var(--color-text)'
        },
        {
          image: `${pathPrefix}ArtDirection2.jpg`,
          textColor: 'var(--color-white)'
        },
        {
          image: `${pathPrefix}ArtDirection3.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Colours':
      return [
        { image: `${pathPrefix}Colour1.jpg`, textColor: 'var(--color-text)' },
        { image: `${pathPrefix}Colour2.jpg`, textColor: 'var(--color-text)' },
        { image: `${pathPrefix}Colour3.jpg`, textColor: 'var(--color-text)' }
      ];
    case 'Data Visualisation':
      return [
        {
          image: `${pathPrefix}DataVisualisation1.jpg`,
          textColor: 'var(--color-text)'
        }
      ];
    case 'Design Examples':
      return [
        {
          image: `${pathPrefix}DesignExamples1.jpg`,
          textColor: 'var(--color-white)'
        },
        {
          image: `${pathPrefix}DesignExamples2.jpg`,
          textColor: 'var(--color-white)'
        },
        {
          image: `${pathPrefix}DesignExamples3.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Design System':
    case 'Graphic System':
      return [
        {
          image: `${pathPrefix}DesignSystem1.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Dot Grid':
    case 'Dot Graphic':
      return [
        {
          image: `${pathPrefix}DotGraphic1.jpg`,
          textColor: 'var(--color-text)'
        },
        {
          image: `${pathPrefix}DotGraphic2.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Gradients':
      return [
        {
          image: `${pathPrefix}Gradient1.jpg`,
          textColor: 'var(--color-white)'
        },
        {
          image: `${pathPrefix}Gradient2.jpg`,
          textColor: 'var(--color-white)'
        },
        { image: `${pathPrefix}Gradient3.jpg`, textColor: 'var(--color-white)' }
      ];
    case 'Iconography':
      return [
        {
          image: `${pathPrefix}Iconography1.jpg`,
          textColor: 'var(--color-text)'
        },
        {
          image: `${pathPrefix}Iconography2.jpg`,
          textColor: 'var(--color-text)'
        }
      ];
    case 'Illustration':
      return [
        {
          image: `${pathPrefix}Illustration1.jpg`,
          textColor: 'var(--color-white)'
        },
        {
          image: `${pathPrefix}Illustration2.jpg`,
          textColor: 'var(--color-black)'
        },
        {
          image: `${pathPrefix}Illustration3.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Logo':
      return [
        { image: `${pathPrefix}Logo1.jpg`, textColor: 'var(--color-white)' },
        { image: `${pathPrefix}Logo2.jpg`, textColor: 'var(--color-white)' },
        { image: `${pathPrefix}Logo3.jpg`, textColor: 'var(--color-white)' }
      ];
    case 'Motion':
      return [
        { image: `${pathPrefix}Motion1.jpg`, textColor: 'var(--color-white)' }
      ];
    case 'Typography':
      return [
        {
          image: `${pathPrefix}Typography1.jpg`,
          textColor: 'var(--color-text)'
        },
        {
          image: `${pathPrefix}Typography2.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Strategy':
      return [
        {
          image: `${pathPrefix}Strategy.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'EVP':
      return [
        {
          image: `${pathPrefix}EVP.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
    case 'Verbal Identity':
      return [
        {
          image: `${pathPrefix}VerbalIdentity.jpg`,
          textColor: 'var(--color-white)'
        }
      ];
  }

  return [];
};

// todo - parse this from figma
const getBlockLinkFromTitle = (title: string) => {
  return `/${toKebabCase(title)}`;
};
